const IsUndefOrNull = (obj) =>
{
    return obj === null || obj === undefined;
}

// We need to be saved as singelton - this isn't really a singelton, just a hack to get the
// updated version of this class even that not the caller has the fw
var instance = null;

// data = current object from state (videoUsageStorage)
// videoStorageAndUsage = expects this object
export class AddRemoveAndChangeVideoStatusUseCase
{
    constructor(fw)
    {
        if (IsUndefOrNull(fw))
        {
            // return the saved part
            return instance;
        }

        this.fw = fw;

        // save ourself
        instance = this;
    }

    AddVideo(videoStorageAndUsage, data, dispatch)
    {
        //console.log("[AddVideo] start");
        if (this.fw.AddVideo(videoStorageAndUsage, data, dispatch) === null)
        {
            return false;
        }
        return true;
    }

    RemoveVideo(videoStorageAndUsage, data, dispatch)
    {
        if (this.fw.RemoveVideo(videoStorageAndUsage, data, dispatch) === null)
        {
            return false;
        }
        return true;
    }

    RemoveVideoList(videoStorageAndUsageList, data, dispatch)
    {
        if (this.fw.RemoveVideoList(videoStorageAndUsageList, data, dispatch) === null)
        {
            return false;
        }
        return true;
    }

    ChangeStatusOfVideo(videoStorageAndUsage, data, dispatch)
    {
        if (this.fw.ChangeVideoStatus(videoStorageAndUsage, data, dispatch) === null)
        {
            return false;
        }
        return true;
    }

    ChangeListStatusOfVideo(videoStorageAndUsage, data, dispatch)
    {
        if (this.fw.ChangeListStatusOfVideo(videoStorageAndUsage, data, dispatch) === null)
        {
            return false;
        }
        return true;
    }
}