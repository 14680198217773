// This is the example interface, as long we are not a typescript we dont have this notion


export class IDataManipulatorFramework
{
    constructor(actualImplementation) {
        this.implementor = actualImplementation;
    }

    AddVideo(videoStorageAndUsage, data, setNew)
    {
        //console.log("[IDataManipulatorFramework::AddVideo] start");
        return this.implementor.AddVideo(videoStorageAndUsage, data, setNew);
    }

    RemoveVideo(videoStorageAndUsage, data, setNew)
    {
        return this.implementor.RemoveVideo(videoStorageAndUsage, data, setNew);
    }

    RemoveVideoList(videoStorageAndUsage, data, setNew)
    {
        return this.implementor.RemoveVideoList(videoStorageAndUsage, data, setNew);
    }

    ChangeVideoStatus(videoStorageAndUsage, data, setNew)
    {
        return this.implementor.ChangeVideoStatus(videoStorageAndUsage, data, setNew);
    }

    ChangeListStatusOfVideo(videoStorageAndUsage, data, setNew)
    {
        return this.implementor.ChangeListStatusOfVideo(videoStorageAndUsage, data, setNew);
    }
};