import { VideoStorageAndUsage } from "../services/file_service/entities/VideoStorageAndUsage";
import { AddRemoveAndChangeVideoStatusUseCase } from "../services/file_service/use_cases/AddRemoveAndChangeVideoStatusUseCase";

const RANDOM_STRING_SIZE = 10;

const generateRandomString = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < RANDOM_STRING_SIZE; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

export const OnFileUploadHandler = (isUserInformationLoading, files_obj, videoUsageStorage, dispatch, cbToSetToNone=null) =>
  {
    // make sure we run the login only if we finish loading
    if (isUserInformationLoading === true)
    {
      setTimeout(OnFileUploadHandler, 100, files);
      return null;
    }

    const files = files_obj.filesUploaded;
    const mapping = files_obj.fileUniqeIdToFileIdMap;
    if (files === null)
    {
      return null;
    }

    // This will handle single file for us
    const newFiles = [];
    //files.forEach(file => newFiles.push(new VideoStorageAndUsage(generateRandomString(), 0, 0, 0, file.file.name, "Processing", 0, file.file.size, Date.now(), Date.now(), null)));
    files.forEach(file => newFiles.push(new VideoStorageAndUsage(mapping.get(file.getMetadata('uniqeId')), 0, 0, 0, file.file.name, "Processing", 0, file.file.size, Date.now(), Date.now(), null)));
    
    const addRemAndChange = new AddRemoveAndChangeVideoStatusUseCase(null);
    addRemAndChange.AddVideo(newFiles, videoUsageStorage, dispatch); // should be filter inside per ID

    // Finish our job
    if (cbToSetToNone !== null)
    {
      cbToSetToNone(null);
    }

    // So I will able to clean up
    return files.map(file => file.getMetadata('videoId'));
  }